import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

let redirected = false;

const PortalWidget = () => {
  const router = useRouter();

  const handlePortal = (e) => {
    if (e.detail.sceneId && !redirected) {
      redirected = true;
      router.replace(`/scene/${e.detail.sceneId}`);
    }
  };

  useEffect(() => {
    if (document) {
      document.addEventListener('intersect_portal_model', handlePortal);
    }

    return () => {
      document.removeEventListener('intersect_portal_model', handlePortal);
    };
  }, []);

  return <></>;
};

export default PortalWidget;
