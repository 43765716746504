import React from 'react';
import styles from './message.module.css';

export default function Message({ message, userName }) {
  return (
    <div className={styles.root}>
      <span
        className={
          userName === message.author ? styles.author : styles.otherAuthor
        }
      >
        {message.author}
        {': '}
      </span>
      <span className={styles.message}>{message.body}</span>
    </div>
  );
}
