import { useEffect, useState } from 'react';
import {
  useGetConversationSidLazyQuery,
  useSetConversationSidMutation,
  useIsChatEnabledInSceneLazyQuery,
} from '@src/components/generated/graphql';
import { Client as ConversationsClient } from '@twilio/conversations';
import Conversation from './Conversation';

export default function ChatWidget({ sceneId }) {
  const [isChatEnabled, setIsChatEnabled] = useState(true);
  const [token, setToken] = useState('');
  const [userName, setUserName] = useState('');
  const [conversationSid, setConversationSid] = useState('' as any);
  const [isInitChat, setIsInitChat] = useState(false);
  const [setConvSid] = useSetConversationSidMutation();
  const [getConvId] = useGetConversationSidLazyQuery();
  const [isChatEnabledQ] = useIsChatEnabledInSceneLazyQuery();

  async function createConversation() {
    const resCreateConv = await fetch('/api/create-conversation', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: sceneId }),
    }).then((res) => res.json());

    if (!resCreateConv?.status || !resCreateConv) {
      setIsChatEnabled(false);
      console.log('Error during the creation of conversation Twillio.');
      return undefined;
    }

    let sid = resCreateConv.conversation.sid as string;
    setConvSid({
      variables: {
        where: {
          id: sceneId,
          sid,
        },
      },
    });

    return sid;
  }

  async function addParticipant(sid, name) {
    await fetch('/api/add-participant', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ conversationSid: sid, name }),
    });
  }

  useEffect(() => {
    async function init() {
      const isEnabled = await isChatEnabledQ({
        variables: {
          where: {
            id: sceneId,
          },
        },
      });

      if (!isEnabled.data?.isChatEnabledInScene.enabled || !isEnabled) {
        setIsChatEnabled(false);
        return;
      }

      const name = localStorage.getItem('username') as string;
      setUserName(name);
      const res = await fetch('/api/get-user-token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name }),
      }).then((res) => res.json());

      if (!res?.status || !res) {
        setIsChatEnabled(false);
        console.log('Error during fetching the user token.');
        return;
      }

      setToken(res.token);

      const resConvSid = await getConvId({
        variables: {
          where: {
            id: sceneId,
          },
        },
      });

      let sid = resConvSid.data?.getConversationSid.sid;

      if (!sid) {
        sid = await createConversation();
      }

      if (!sid) {
        setIsChatEnabled(false);
        return;
      }

      await addParticipant(sid, name);
      let conversation;

      try {
        const conversationsClient = new ConversationsClient(res.token);
        conversation = await conversationsClient.getConversationBySid(sid);
      } catch (e) {
        console.error(e);
        setIsChatEnabled(false);
        return;
      }

      if (conversation.state?.current !== 'active') {
        sid = await createConversation();
      }

      setConversationSid(sid);
      setIsInitChat(true);
      await addParticipant(sid, name);
    }

    init();
  }, []);

  if (!isChatEnabled) {
    return <></>;
  }

  return (
    <div>
      <Conversation
        conversationSid={conversationSid}
        token={token}
        initializedChat={isInitChat}
        userName={userName}
      />
    </div>
  );
}
