import React from 'react';
import Message from './Message';

export default function Messages({ messages, userName }) {
  return (
    <div>
      {messages.map((e, i) => (
        <Message message={e} key={i} userName={userName} />
      ))}
    </div>
  );
}
