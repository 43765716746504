import React, { useEffect, useState } from 'react';
import { Client as ConversationsClient } from '@twilio/conversations';
import { Input, Button } from 'antd';
import { ChatIcon, SendIcon, EmojiIcon } from './ChatIcon';
import { CloseOutlined } from '@ant-design/icons';
import Messages from './Messages';
import { createPicker } from 'picmo';
import styles from './conversation.module.css';

export default function Conversation({
  conversationSid,
  token,
  initializedChat,
  userName,
}) {
  const [messages, setMessages] = useState([] as any);
  const [collapsed, setCollapsed] = useState(true);
  const [inputValue, setInputValue] = useState('' as any);
  const [client, setClient] = useState({} as any);
  const [openedEmoji, setOpenedEmoji] = useState(null as any);
  const [conversation, setConversation] = useState(null as any);

  useEffect(() => {
    async function init() {
      const conversationsClient = new ConversationsClient(token);
      const conversation = await conversationsClient.getConversationBySid(
        conversationSid
      );
      const messages = await conversation.getMessages();
      setMessages(messages.items);
      setClient(conversationsClient);
      setConversation(conversation);

      conversation.on('messageAdded', (message) => messageAdded(message));
    }

    if (initializedChat) {
      init();
    }

    return () => {
      if (initializedChat) {
        conversation.removeAllListeners();
      }
    };
  }, [initializedChat]);

  const sendMessage = async () => {
    if (!inputValue) {
      return;
    }
    setInputValue('');
    await conversation.sendMessage(inputValue);
  };

  const messageAdded = (message) => {
    setMessages((prevState) => [...prevState, message]);
  };

  const openEmoji = () => {
    const rootElement = document.querySelector('#emoji-picker') as any;
    const picker = createPicker({ rootElement });

    const close = () => {
      picker.removeEventListener('emoji:select', callback);
      picker.destroy();
      setOpenedEmoji(null);
    };

    const callback = (event) => {
      setInputValue((prev) => prev + event.emoji);
      close();
    };

    picker.addEventListener('emoji:select', callback);

    return () => close();
  };

  const closeEmoji = (callback) => callback();

  return (
    <>
      <Button
        className={styles.collapseButton}
        onClick={() => setCollapsed(!collapsed)}
      >
        {ChatIcon}
      </Button>

      {!collapsed && (
        <div className={styles.root}>
          <div className={styles.title}>
            Chat <CloseOutlined onClick={() => setCollapsed(!collapsed)} />
          </div>
          <div className={styles.messagesContainer}>
            <Messages messages={messages} userName={userName} />
          </div>
          <div className={styles.formContainer}>
            <div className={styles.form}>
              <div style={{ width: '100%' }}>
                <Input
                  value={inputValue}
                  onChange={(v) => setInputValue(v.target.value)}
                  placeholder='Type your message here...'
                  id='nftNameInput'
                  className={styles.input}
                  autoComplete='off'
                />
              </div>
              <div id='emoji-picker' className={styles.emojiPicker} />
              <Button
                type='text'
                className={styles.sendButton}
                onClick={() => {
                  if (openedEmoji) {
                    closeEmoji(openedEmoji);
                  } else {
                    setOpenedEmoji(() => openEmoji());
                  }
                }}
              >
                {EmojiIcon}
              </Button>
              <Button
                onClick={sendMessage}
                type='text'
                className={styles.sendButton}
              >
                {SendIcon}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
