import { useEffect, useState } from 'react';
import { useIsScanEnabledInSceneLazyQuery } from '@src/components/generated/graphql';

export default function ScanWidget({ sceneId }) {
  const [isScanEnabled, setIsScanEnabled] = useState(false);
  const [isScanEnabledQ] = useIsScanEnabledInSceneLazyQuery();
  const [message, setMessage] = useState('');

  const scanEvent = (e) => {
    setMessage(e.detail.text || '');
  };

  useEffect(() => {
    async function init() {
      try {
        const { data } = await isScanEnabledQ({
          variables: {
            where: {
              id: sceneId,
            },
          },
        });
        const { enabled }: any = data?.isScanEnabledInScene || false;
        setIsScanEnabled(() => enabled);
        if (enabled) {
          document.addEventListener('scanEvent', scanEvent);
        }
      } catch (error) {
        console.log(error);
      }
    }

    init();

    return () => {
      if (isScanEnabled) {
        document.removeEventListener('scanEvent', scanEvent);
      }
    };
  }, []);

  if (!isScanEnabled) {
    return <></>;
  }

  return (
    <>
      <div className='scanContainer'>
        <span
          onClick={() => {
            setMessage('');
          }}
          className='scan-span'
        >
          {message || 'No Data'}
        </span>
      </div>
      <style>
        {`.scanContainer{
						position: absolute;
						z-index: 10;
						top: 10px;
						left: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
						pointer-events: none;
					}
					.scan-span {
						padding: 0.5em;
						border: 1px solid black;
						border-radius: 8px;
						color: white;
						font-weight: bold;
						height: 40px;
						background: rgba(125,125,125,0.25);
						opacity: 1;
						transform: scale(1);
						transition: opacity 0.3s ease, transform 0.3s ease;
						cursor:pointer;
					}
					
					`}
      </style>
    </>
  );
}
